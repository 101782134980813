import { Injectable } from '@angular/core';
import { API_ENDPOINT } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
//import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

//declare var cordova: any;

interface Coupon {
  code: string,
  percentage: number
}

const CURRENCY_KEY  = 'SELECTED_CURRENCY';
const COUPON_KEY    = 'COUPON_CCODE';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  img_url: string = `${API_ENDPOINT}/static/img/`;
  static_url: string = `${API_ENDPOINT}/static/`;
  api_url: string = `${API_ENDPOINT}/apiv3/`;
  api_hash: string =
    '$2y$10$ctFIrjWOLKBYV8lH670c9OxdTAKDgJKQ.5O0kaaEeHflJr1LBaCjS';

  user_hash: string = '';
  user_id: string = '';
  notifi_id: string = '';

  device: string = '';
  versionApp: string = '4.1.9';

  countries: any = [];
  currencies: any = [{"id":"45","country_id":"12","name":"ريال سعودي","code":"SAR","exchange":"1","country_name":"السعودية","code_country":"+966"}];

  pages: any = [];

  selectedCurrency: any = {"id":"45","country_id":"12","name":"ريال سعودي","code":"SAR","exchange":"1","country_name":"السعودية","code_country":"+966"};

  new_order: any = [];
  ordersState = new BehaviorSubject([]);
  ordersGState = new BehaviorSubject([]);

  settings: any = {};
  packs:any = {};
  sliders:any = {};

  URL_APP: string = "";
  wallet_active: boolean = false;
  free_umrah: boolean = false;
  myfatoorah: boolean = false;
  tappay: boolean = false;
  joined: boolean = true;
  free_img: string = "";

  notificationsState = new BehaviorSubject([]);

  nbrNotification: any = 0;
  nbrRequest: any = 0;
  nbrContact: any = 0;

  myWallet: any = 0;
  soldeUsed: any = 0;

  fileTransferObject: FileTransferObject;

  currentInvoice;

  coupon: Coupon = { code: null, percentage: 0 };

  constructor(public http: HttpClient,
              public fb: Facebook, 
              public toast: ToastController,
              private storage: Storage,
              private platform: Platform,
              private file: File,
              private sharing: SocialSharing,
              private androidPermissions: AndroidPermissions,
              private fileTransfer: FileTransfer,
              ) 
              { }

  async showToast(msg) {
    let toast = await this.toast.create({
      message: msg,
      position: 'top',
      duration: 2500
    });
    toast.present();
  }

  cancelCoupon() {

    this.coupon = { code: null, percentage: 0 };
    //this.storage.set(COUPON_KEY, this.coupon);
    this.storage.remove(COUPON_KEY+"_"+this.user_id);

  }

  setCoupon(coupon) {

    this.coupon = coupon;
    this.storage.set(COUPON_KEY+"_"+this.user_id, this.coupon).then(result=> {
      //alert( JSON.stringify(result) );
    });

    

  }

  getCoupon() {

    //alert(COUPON_KEY+"_"+this.user_id);

    this.storage.get(COUPON_KEY+"_"+this.user_id).then(coupon => {

      //alert("coupon : "+ JSON.stringify(coupon) );

      if (coupon) {

        this.setCoupon(coupon);

      } else {

        this.cancelCoupon();
        
      }
    });
    
  }

  set_walletActive(value) {
    this.wallet_active = value;
  }

  set_freeUmrah(value) {
    this.free_umrah = value;
  }

  set_freeImage(value) {
    this.free_img = value;
  }

  set_myfatoorah(value) {
    this.myfatoorah = value;
  }

  set_tappay(value) {
    this.tappay = value;
  }

  set_join(value) {
    this.joined = value;
  }

  set_versionApp(version) {
    this.versionApp = version;
  }

  //
  set_urlApp(urlApp) {
    this.URL_APP = urlApp;
  }

  //
  get_sliders() {
    return this.sliders;
  }

  //
  set_sliders(sliders) {

    if( JSON.stringify(sliders) != JSON.stringify(this.sliders) ) {

      this.sliders = sliders;

    }
    
  }

  get_settings() {
    return this.settings;
  }

  set_settings(settings) {
    if( JSON.stringify(settings) != JSON.stringify(this.settings) ) {
      this.settings = settings;
    }
  }

  set_packs(packs) {

    if( JSON.stringify(packs) != JSON.stringify(this.packs) ) {

      this.packs = packs;

    }

  }

  get_packs() {
    return this.packs;
  }

  getOrders() {
    return this.ordersState.value;
  }

  setOrders(orders) {
    this.ordersState.next(orders);
  }

  //
  setOrdersGift(orders) {
    this.ordersGState.next(orders);
  }

  getOrdersGift() {
    return this.ordersGState.value;
  }

  addOrder(order) {
    let orders = this.getOrders();
    orders.unshift(order);
    this.setOrders(orders);
  }

  set_country(countries) {
    if( JSON.stringify(countries) != JSON.stringify(this.countries) ) {
      this.countries = countries;
    }
  }

  set_pages(pages) {
    if( JSON.stringify(pages) != JSON.stringify(this.pages) ) {
      this.pages = pages;
    }
    //alert(JSON.stringify(pages));
  }

  set_currencies(currencies) {
    if( JSON.stringify(currencies) != JSON.stringify(this.currencies) ) {
      this.currencies = currencies;
    }
  }

  get_selectedCurrency(){
    return this.selectedCurrency;
  }

  setSelectedCurrency(currency) {
    this.selectedCurrency = currency;
    this.storage.set(CURRENCY_KEY, currency);
  }

  getDefaultCurrency() {

    this.storage.get(CURRENCY_KEY).then(currency => {

      //alert("currency : "+ JSON.stringify(currency) );

      if (currency) {
        this.setSelectedCurrency(currency);
      } else {
        this.setSelectedCurrency(this.currencies[0]);
        //alert("currency : "+ JSON.stringify(this.currencies[0]) );
      }
    });
  }

  set_nbrNotification(value) {
    this.nbrNotification = value;
  }

  set_nbrRequest(value) {
    this.nbrRequest = value;
  }

  set_nbrContact(value) {
    this.nbrContact = value;
  }

  set_myWallet(value) {
    this.myWallet = value;
  }

  set_soldeUsed(value) {
    this.soldeUsed = value;
  }

  public downloadFile(url: string): any {
      return this.performDownload(url);
  }

  protected async performDownload(url: string){
      // We added this check since this is only intended to work on devices and emulators 
      if (!this.platform.is('cordova')) {
          console.warn('Cannot download in local environment!');
          return;
      }

      const fileTransfer: FileTransferObject = this.fileTransfer.create();

      let uri           = encodeURI(url);
      let fullFileName  = url.substring(url.lastIndexOf('/') + 1);
      let path          = await this.getDownloadPath();

      return fileTransfer.download(
          uri,
          path + fullFileName,
          false
      )/*.then(
          result => {
              //this.showAlert(true, fileName);
              //alert(JSON.stringify(result));
              
              //this.photoLibrary.saveVideo(result.nativeURL, "Umrah");
              
              var options = {
                message: '', // not supported on some apps (Facebook, Instagram)
                subject: '', // fi. for email
                files: [result.nativeURL], // an array of filenames either locally or remotely
              };
              
              this.sharing.shareWithOptions(options);

          },
          error => {
              //alert(JSON.stringify(error));
              this.showToast( JSON.stringify(error) );
              //this.showAlert(false, fileName);
          }
      )*/
    }

    public async getDownloadPath() {
      if (this.platform.is('ios')) {
          return this.file.documentsDirectory;
      }

    // To be able to save files on Android, we first need to ask the user for permission. 
    // We do not let the download proceed until they grant access
      await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
          result => {
              if (!result.hasPermission) {
                  return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
              }
          }
      );

      return this.file.externalRootDirectory + "Download/";
  }

  post_file(url: string, file: string, body?: any) {

    const fileTransfer: FileTransferObject = this.fileTransfer.create();

    let options: FileUploadOptions = {
      fileKey: "file",
      fileName: file.substring(file.lastIndexOf("/") + 1),
      httpMethod: "POST",
      chunkedMode: false,
      //mimeType: "multipart/form-data",
      params: body, //this.buildFormData(body),
      headers: {
        'api-hash': this.api_hash,
        hash: this.user_hash || '',
        'user-id': this.user_id || '',
        //'notifi_id': this.notifi_id,
        'device': this.device || ''
      }
    };

    return fileTransfer.upload(
      file,
      encodeURI(this.api_url + url),
      options
    );

  }

  post_request(url: string, body?: any) {
    return this.http.post(this.api_url + url, this.buildFormData(body), {
      headers: new HttpHeaders({
        'api-hash': this.api_hash,
        hash: this.user_hash || '',
        'user-id': this.user_id || '',
        //'notifi_id': this.notifi_id,
        'device': this.device || ''
      })
    });
  }

  get_request(url?: string, params?: any) {

    //alert(this.device);

    //alert("Token FCM get fun :"+this.notifi_id);
    let headers = new HttpHeaders({
      'api-hash': this.api_hash,
      hash: this.user_hash || '',
      'user-id': this.user_id || '',
      //'notifi_id': this.notifi_id,
      'device': this.device || ''
    });

    //alert(JSON.stringify(headers));

    return this.http.get(this.api_url + url, {
      params: params,
      headers: headers
    });
  }

  /*get_json(url?: string, params?: any) {

    //alert(this.device);
    let headers = new HttpHeaders({
      'api-hash': this.api_hash,
      hash: this.user_hash || '',
      'user-id': this.user_id || '',
      'device': this.device || '',
      'notifi_id': this.notifi_id
    });

    return this.http.get(this.static_url + url, {
      params: params,
      headers: headers
    });
  }*/

  buildFormData(data) {
    let fd = new FormData();
    if (!data || typeof data !== 'object') {
      return fd;
    }
    Object.keys(data).forEach(key => {
      if (typeof data[key] !== 'string') {
        fd.append(key, JSON.stringify(data[key]));
      } else {
        fd.append(key, data[key]);
      }
    });
    return fd;
  }

  set_storage_user(user_hash?, user_id?) {
    this.user_hash = user_hash || '';
    this.user_id = user_id || '';
  }

  convertN2E(string) {
    let str = string
      .trim()
      .replace(/[\u0660-\u0669]/g, function(c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function(c) {
        return c.charCodeAt(0) - 0x06f0;
      });
    return !parseInt(str) ? null : str;
  }

  setNotifications(notifi) {
    this.notificationsState.next(notifi);
  }

  addNotifications(notifi) {
    let notifications = this.getOrders();
    notifications.unshift(notifi);
    this.setNotifications(notifications);
  }

  //
  facebookEvent(name, params?, valueToSum?) {
    this.fb.logEvent(name, params, valueToSum).then(data=>{
      //alert("Ok Event : "+JSON.stringify(data));
    }).catch(error=>{
      //alert("Error");
    })
  }

  //
  facebookEventPusrchase(price, params?) {
    this.fb.logPurchase(price, this.selectedCurrency.code, params).then(data=>{
      //alert('OK');
    }).catch(error=>{
      //alert('Error');
    });
  }

}