import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
//import { API_ENDPOINT } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CustomTranslateLoader implements TranslateLoader  {

    static_url: string = ""; //`${API_ENDPOINT}/static/`;
    api_hash: string = '$2y$10$ctFIrjWOLKBYV8lH670c9OxdTAKDgJKQ.5O0kaaEeHflJr1LBaCjS';

    constructor(private apiService: ApiService, private http: HttpClient) {}

    getTranslation(lang: string): Observable<any>{

        //alert(this.apiService.api_url);

        /*let contentHeader = new HttpHeaders({"Content-Type": "application/json",
                                              "Access-Control-Allow-Origin":"*",
                                              "Access-Control-Allow-Methods":"GET, POST, OPTIONS",
                                              "api-hash": this.api_hash});

        

        //alert( JSON.stringify(contentHeader) );*/

        //var apiAddress = "i18n/"+ lang+".json";

        return Observable.create(observer => {
          
          this.apiService.get_request('account/init_lang?lang='+lang).subscribe((res: any) => {
            //alert(lang+" = res "+res.ar);
            let data = res.ar;

            //alert(JSON.stringify(res));

            if( lang == 'en' ){
              data = res.en;
            }

            observer.next(data);
            observer.complete();               
            },

          error => {
              //  failed to retrieve from api, switch to local
              this.http.get("/assets/i18n/"+lang+".json").subscribe((res: any) => {
                  observer.next(res);
                  observer.complete();               
              })
            }
          );

          /*this.http.get(apiAddress, { headers: contentHeader }).subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();               
                },
            error => {
                //  failed to retrieve from api, switch to local
                this.http.get("/assets/i18n/"+lang+".json").subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();               
                })
            }
            );*/
        });
        
    }
}