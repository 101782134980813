import { Component, ViewChild } from '@angular/core';
import { Platform, NavController, IonRouterOutlet } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AuthService } from './services/auth.service';
//import { ApiService } from './services/api.service';
import { Storage } from '@ionic/storage';
import { LanguageService } from './services/language.service';
import { ApiService } from './services/api.service';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { environment } from '../environments/environment';

import {
  Idfa,
  AdvertisingInfoResponse,
} from '@sparkfabrik/capacitor-plugin-idfa';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

//import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { read: null, static: true })
  routerOutlet: IonRouterOutlet;

  pushPayload;
  hasPermission;

  constructor(
    private platform: Platform,
    //private fcm: FCM,
    private fb: Facebook,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private languageService: LanguageService,
    private authService: AuthService,
    private navCtrl: NavController,
    /*private alertCtrl: AlertController,
    private translate: TranslateService,*/
    private storage: Storage,
    private appVersion: AppVersion,
    private apiService: ApiService,
    private nativeAudio: NativeAudio
  ) {
    
    this.initializeApp();
    this.firebaseAnalytics();
  }

  //
  firebaseAnalytics() {

    FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);

    FirebaseAnalytics.setCollectionEnabled({
      enabled: false,
    });

    FirebaseAnalytics.enable();

    //alert( JSON.stringify(FirebaseAnalytics.getAppInstanceId()) );

  }

  //
  initIdfa() {

    Idfa.getAdvertisingInfo()
    .then((response: AdvertisingInfoResponse) => {
      if (response.isAdTrackingLimited === true) {
        console.error('Ads tracking not allowed by user.');
      }
      console.log(response.id);
    })
    .catch((err: Error) => {
      console.error(err);
    });

  }
  //
  initializeApp() {

    this.platform.ready().then(() => {

      this.platform.resume.subscribe(() => {

        if( this.platform.is("cordova") ) {

         
          //PushNotifications.removeAllDeliveredNotifications();
          this.push();
        }

      });

      this.apiService.device = this.platform.is("ios")?"ios":"android";

      this.initSoundNotify();
      this.push();
      this.initIdfa();
      this.facebookInit();
      //this.initFCM();
      //this.initGoogleAnalytics();

      //alert(this.apiService.device);

      //Version app
      this.appVersion.getVersionNumber().then( (value) => {

        this.apiService.set_versionApp(value);

        //alert(value);

      });

      //alert(this.apiService.device);

      this.storage.get("COLORTHEME").then(theme => {

        if( theme != undefined){
          document.body.setAttribute('color-theme', theme);



        }else {

          document.body.setAttribute('color-theme', 'light');
          this.statusBar.styleDefault();

        }

      });

      this.statusBar.overlaysWebView(false);
      //this.statusBar.backgroundColorByHexString('#000000');
      //this.statusBar.styleLightContent();

      //this.statusBar.styleDefault();
      
      this.splashScreen.hide();

      this.languageService.setInitialAppLanguage();

      this.authService.getUser();
      
      //this.authService.getUser();
      
      this.authService.authenticationState.subscribe(state => {

        //alert(state);

        if (!this.authService.isLoading() && !this.authService.isError()) {
          if (state) {

            //alert(this.authService.next_page || '/tabs/tab1');
            //alert(state);

            /*this.navCtrl.navigateRoot(
              this.authService.next_page || '/tabs/tab1'
            );*/

            this.navCtrl.navigateRoot('/tabs/tab1');
            //this.navCtrl.navigateRoot('/plans/plans');

          } else {
            this.navCtrl.navigateRoot('/welcome');
          }
        }
      });

      //this.initNotifications();

      // Close App after double click on hardware back btn
      let lastBackClick = 0;
      let timeToExit = 300;
      
      this.platform.backButton.subscribe(async () => {
        if (!this.routerOutlet.canGoBack()) {
          let _d = new Date();
          if (_d.getTime() - timeToExit < lastBackClick) {
            navigator['app'].exitApp();
          } else {
            lastBackClick = _d.getTime();
          }
        }
      });
      // End of back btn
    });
  }

  //
  facebookInit() {

    this.fb.setAdvertiserTrackingEnabled(true).then( ()=>{
      console.log('setAdvertiserTrackingEnabled success');
    }).catch( (error)=> {
      console.error('setAdvertiserTrackingEnabled failure');
    });

  }
  //
  initSoundNotify() {

    this.nativeAudio.preloadSimple('beep', 'assets/sounds/beep_in.mp3').then(onSuccess=>{

    }, onError=>{

    });

  }

  push() {

    if( this.platform.is("cordova") ) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
          //this.showErrorPush();
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          //alert('Push registration success, token: ' + token.value);
          this.apiService.notifi_id = token.value;
          //this.apiService.
          //alert(this.apiService.notifi_id);

          PushNotifications.removeAllDeliveredNotifications();
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          //alert('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          //alert('Push received: ' + JSON.stringify(notification));
          this.apiService.showToast(notification.body);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          //alert('Push action performed: ' + JSON.stringify(notification));
        }
      );

      //PushNotifications.removeAllDeliveredNotifications();

    }
  }

  /*async showErrorPush() {

    const alert = await this.alertCtrl.create({
      backdropDismiss: false,
      subHeader: this.translate.instant('error_push_disabled'),
      buttons: [
        {
          text: this.translate.instant('Ok'),
          handler: () => {
            //PushNotifications.checkPermissions
          }
        },
        {
          role: 'cancel',
          text: this.translate.instant('Cancel')
        }
      ]
    });

    alert.present();
  }*/

  /*async initGoogleAnalytics() {

    this.ga.startTrackerWithId('UA-93870609-1')
    .then(() => {
      console.log('Google analytics is ready now');
        this.ga.trackView('test');
      // Tracker is ready
      // You can now track pages or set additional information such as AppVersion or UserId
    })
    .catch(e => console.log('Error starting GoogleAnalytics', e));

  }*/

  /*async initFCM(){

    if( this.platform.is("cordova") ) {


      this.fcm.requestPushPermission({
        ios9Support: {
          timeout: 10,  // How long it will wait for a decision from the user before returning `false`
          interval: 0.3 // How long between each permission verification
        }
      }).then(hasPermission => {
        if (hasPermission) {
          console.log("Has permission!");
        }
      });

      console.log('Subscribing to token updates');
      this.fcm.onTokenRefresh().subscribe((newToken) => {
        this.apiService.notifi_id = newToken;
        console.log('onTokenRefresh received event with: ', newToken);

        //alert(newToken);

      });

      console.log('Subscribing to new notifications');
      this.fcm.onNotification().subscribe((payload) => {
        //this.pushPayload = payload;

        //alert(JSON.stringify(payload));

        if(payload.wasTapped){

          console.log("Received in background");

        } else { //Sound interne
          
          console.log("Received in foreground");

          this.nativeAudio.play('beep').then(onSuccess=>{

          }, onError=>{

          });

          this.apiService.showToast(payload.title);

        };

        console.log('onNotification received event with: ', payload);
      });

      this.hasPermission = await this.fcm.requestPushPermission();
      console.log('requestPushPermission result: ', this.hasPermission);

      this.apiService.notifi_id = await this.fcm.getToken();
      console.log('getToken result: ', this.apiService.notifi_id);

      this.pushPayload = await this.fcm.getInitialPushPayload();
      console.log('getInitialPushPayload result: ', this.pushPayload);

      this.fcm.clearAllNotifications();

    }

  }*/

}
