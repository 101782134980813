// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAa-C2P7Wi1zRerjC3tFDTfyvTVpO0rtBY",
    authDomain: "umrahalbadal-ionic-v5.firebaseapp.com",
    projectId: "umrahalbadal-ionic-v5",
    storageBucket: "umrahalbadal-ionic-v5.appspot.com",
    messagingSenderId: "297617844901",
    appId: "1:297617844901:web:fa4baa03bea3d23cf6bb4c",
    measurementId: "G-QZ1M8C6YSY"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const API_ENDPOINT = 'https://umrcp.com/cp';