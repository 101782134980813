import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'images',
    loadChildren: () => import('./pages/images/images.module').then( m => m.ImagesPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'order-details',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./pages/language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'currency',
    loadChildren: () => import('./pages/currency/currency.module').then( m => m.CurrencyPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'coupon',
    loadChildren: () => import('./pages/coupon/coupon.module').then( m => m.CouponPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'new-order',
    loadChildren: () => import('./pages/new-order/new-order.module').then( m => m.NewOrderPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'pay',
    loadChildren: () => import('./pages/pay/pay.module').then( m => m.PayPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'pay-success',
    loadChildren: () => import('./pages/pay-success/pay-success.module').then( m => m.PaySuccessPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'plans',
    loadChildren: () => import('./pages/plans/plans.module').then( m => m.PlansPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'close',
    loadChildren: () => import('./pages/close/close.module').then( m => m.ClosePageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'countries',
    loadChildren: () => import('./pages/countries/countries.module').then( m => m.CountriesPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies.module').then( m => m.CompaniesPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'joining',
    loadChildren: () => import('./pages/joining/joining.module').then( m => m.JoiningPageModule)
  },
  {
    path: 'reset-pass',
    loadChildren: () => import('./pages/reset-pass/reset-pass.module').then( m => m.ResetPassPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'edit-data',
    loadChildren: () => import('./pages/edit-data/edit-data.module').then( m => m.EditDataPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'wallet',
    loadChildren: () => import('./pages/wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'free-umrah',
    loadChildren: () => import('./pages/free-umrah/free-umrah.module').then( m => m.FreeUmrahPageModule)
  },

  {
    pathMatch: 'full',
    path: '',
    redirectTo: '/home'
  },
  {
    path: 'terms-modal',
    loadChildren: () => import('./pages/terms-modal/terms-modal.module').then( m => m.TermsModalPageModule)
  },
  {
    path: 'phone-verify',
    loadChildren: () => import('./pages/phone-verify/phone-verify.module').then( m => m.PhoneVerifyPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'contactus',
    loadChildren: () => import('./pages/contactus/contactus.module').then( m => m.ContactusPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'edit-order',
    loadChildren: () => import('./pages/edit-order/edit-order.module').then( m => m.EditOrderPageModule)
  },  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'pay-free',
    loadChildren: () => import('./pages/pay-free/pay-free.module').then( m => m.PayFreePageModule)
  },
  {
    path: 'pay-wallet',
    loadChildren: () => import('./pages/pay-wallet/pay-wallet.module').then( m => m.PayWalletPageModule)
  },
  {
    path: 'myfatoorah',
    loadChildren: () => import('./pages/myfatoorah/myfatoorah.module').then( m => m.MyfatoorahPageModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./pages/invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'myfatoorah-free',
    loadChildren: () => import('./pages/myfatoorah-free/myfatoorah-free.module').then( m => m.MyfatoorahFreePageModule)
  },
  {
    path: 'reorder',
    loadChildren: () => import('./pages/reorder/reorder.module').then( m => m.ReorderPageModule)
  },




  /*{
    path: '**',
    redirectTo: '/home'
  }*/

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
