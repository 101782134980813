import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { ApiService } from 'src/app/services/api.service';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  selected = '';
  //selectedCurrency: any = null;

  /*defaultCurrency = {
    name: 'دولار امريكي',
    code: 'USD',
    amount: {
      in: 200,
      inno: 133.3
    }
  };*/

  constructor(private translate: TranslateService, 
              private storage: Storage,
              @Inject(DOCUMENT) private document,
              private platform: Platform,
              private api: ApiService) {

  }

  setInitialAppLanguage() {

    let defaultLang = 'ar';
    let language = this.translate.getBrowserLang();
    let languages = this.getLanguages();

    for (let item of languages) {
      if (item.value == language) {
        defaultLang = language;
        break;
      }
    }

    this.selected = defaultLang;
    this.translate.setDefaultLang(defaultLang);

    this.storage.get(LNG_KEY).then(val => {

      //alert("Selected Lang : "+val);

      if (val) {
        this.translate.setDefaultLang(val);
        this.setLanguage(val);
        this.selected = val;

        if(this.selected == 'ar'){
          this.setDir('rtl');
        }else {
          this.setDir('ltr');
        }

      }
    });

    if(this.selected == 'ar'){
      this.setDir('rtl');
    }else {
      this.setDir('ltr');
    }

    /*this.storage.get(CURRENCY_KEY).then(currency => {

      alert("currency : "+currency);

      if (currency) {
        this.setCurrency(currency);
      } else {
        this.setCurrency(this.getCurrencies[0]);
        alert("currency : "+this.getCurrencies[0]);
      }
    });*/
  }

  getLanguages() {
    return [
      { text: 'العربية', value: 'ar', img: 'assets/imgs/ar.png' },
      { text: 'English', value: 'en', img: 'assets/imgs/en.png' }/*,
      { text: 'français', value: 'fr', img: 'assets/imgs/fr.png' },
      { text: 'Türkçe', value: 'tr', img: 'assets/imgs/tr.png' },
      { text: '中國', value: 'cn', img: 'assets/imgs/cn.png' },
      { text: 'Espanol', value: 'es', img: 'assets/imgs/es.png' },
      { text: 'اُردُو', value: 'pk', img: 'assets/imgs/pk.png' },
      { text: 'فارسي', value: 'ir', img: 'assets/imgs/ir.png' },
      { text: 'भारतीय', value: 'in', img: 'assets/imgs/in.png' }*/
    ];
  }

  /*get getCurrencies() {
    return this.api.currencies;
  }*/

  setLanguage(lng) {

    //alert("Lang : "+lng);

    this.translate.use(lng);
    this.selected = lng;
    this.storage.set(LNG_KEY, lng);

    if(lng == 'ar'){
      this.setDir('rtl');
    }else {
      this.setDir('ltr');
    }

  }

  /*setCurrency(currency) {
    this.selectedCurrency = currency;
    this.storage.set(CURRENCY_KEY, currency);
  }*/

  setDir(dir: string) {
    this.document.dir = dir;
  }
}
