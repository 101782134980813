import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  next_page: string = null;

  user: any = null;

  authenticationState = new BehaviorSubject(false);
  loadingState = new BehaviorSubject(true);
  errorState = new BehaviorSubject(false);

  constructor(private api: ApiService, 
              private storage: Storage,
              private router: Router,
              private navCtrl: NavController) { }

  async getUser() {
    
    this.loadingState.next(true);
    this.errorState.next(false);

    let user = await this.storage.get('user-x');

    if (user) {
      this.api.set_storage_user(user.hash, user.id);
    }
    
    this.api.getCoupon();
    this.api.getDefaultCurrency();

    //alert(this.api.notifi_id);

    this.api.get_request('account/init_data?coupon_code='+this.api.coupon.code+'&version_code='+this.api.versionApp+'&notify_id='+this.api.notifi_id).subscribe(
      (res: any) => {

        //console.log(JSON.stringify(res));

        //alert(JSON.stringify(res.coupon));

        this.loadingState.next(false);
        this.errorState.next(false);

        if (res.user) {
          this.login(res.user);
        } else {
          this.logout();
        }

        this.api.set_currencies( res.currencies );
        this.api.set_country( res.countries );
        this.api.set_pages( res.pages );
        this.api.set_settings( res.settings );
        this.api.set_packs( res.packs );
        this.api.set_sliders( res.sliders );
        this.api.set_urlApp( res.url_app );
        
        this.api.set_freeImage( res.free_img );
        this.api.set_walletActive( res.wallet_act == 1 );
        this.api.set_freeUmrah( res.free_umrah == 1 );
        this.api.set_myfatoorah( res.myfatoorah == 1 );
        this.api.set_tappay( res.tappay == 1 );
        this.api.set_join( res.joined == 1 );

        this.api.set_nbrNotification( res.nbrNotification );
        this.api.set_nbrRequest( res.nbrRequest );
        this.api.set_nbrContact( res.nbrContact);

        this.api.set_myWallet(res.wallet);

        this.api.set_soldeUsed(res.soldeUsed);

        // /alert(res.nbrNotification);

        if( res.coupon != null ) {
          
          this.api.setCoupon(res.coupon);

        }
        //alert(JSON.stringify(res.settings));

        /*if( res.version == 0 ) {
          
          this.navCtrl.navigateRoot('/update');
          //this.setNextPage('/update');

        }else {
          
          this.navCtrl.navigateRoot('/tab/tab1');

        }*/

      },
      err => {
        this.errorState.next(true);
        this.loadingState.next(false);
      }
    );

    //Update data

    setInterval(() => { 
      this.updateData();
    }, 1000);

  }

  async updateData() {

    //console.log("Update Data++++++++");

    this.api.get_request('account/init_data?coupon_code='+this.api.coupon.code+'&version_code='+this.api.versionApp+'&notify_id='+this.api.notifi_id).subscribe(
      (res: any) => {

        //alert(JSON.stringify(res.coupon));

        //console.log("From server : "+res.notifi_id);

        this.api.set_country(res.countries);
        this.api.set_pages(res.pages);
        this.api.set_currencies(res.currencies);
        this.api.set_settings(res.settings);
        this.api.set_packs(res.packs);
        this.api.set_sliders(res.sliders);
        this.api.set_urlApp(res.url_app);

        this.api.set_freeImage(res.free_img);
        this.api.set_walletActive( res.wallet_act == 1 );
        this.api.set_freeUmrah(res.free_umrah == 1);
        this.api.set_myfatoorah(res.myfatoorah == 1);
        this.api.set_tappay(res.tappay == 1);
        this.api.set_join(res.joined == 1);

        this.api.set_nbrNotification( res.nbrNotification );
        this.api.set_nbrRequest( res.nbrRequest );
        this.api.set_nbrContact( res.nbrContact);

        this.api.set_myWallet(res.wallet);

        this.api.set_soldeUsed(res.soldeUsed);
        
        //alert(res.nbrNotification);

        if( res.coupon != null ) {
          
          this.api.setCoupon(res.coupon);

        }

        //console.log( "Version : "+ res.version );

        //console.log("under "+ res.under_co );
        //console.log("url "+ res.url_app );

        //Update App;
        if( res.version == 0 ) {
          
          this.navCtrl.navigateRoot('/update');
          
        }else {

          if( this.router.url == '/update' ) { //If page update return to tabs

            this.navCtrl.navigateRoot('/tabs/tab1');

          }

        }

        //Under counstr
        if( res.under_co == "1" ) {
          
          this.navCtrl.navigateRoot('/close');
          
        }else {

          if( this.router.url == '/close' ) { //If page update return to tabs

            this.navCtrl.navigateRoot('/tabs/tab1');

          }

        }

      },
      err => {
        //this.errorState.next(true);
        //this.loadingState.next(false);

        console.log("Error init Data : ", JSON.stringify(err));

      }
    );
  }

  async login(user?) {
    this.authenticationState.next(true);
    this.user = user;
    this.api.set_storage_user(user.hash, user.id);

    FirebaseAnalytics.setUserId({
      userId: user.id,
    });

    await this.storage.set('user-x', user);
  }

  async logout() {
    this.api.get_request('account/logout').subscribe();
    this.authenticationState.next(false);
    this.user = null;
    this.api.set_storage_user();
    await this.storage.remove('user-x');
  }

  isError() {
    return this.errorState.value;
  }

  isLoading() {
    return this.loadingState.value;
  }

  isAuthenticate() {
    return this.authenticationState.value;
  }

  setNextPage(page) {
    this.next_page = page;
  }

}
