import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
//import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AuthService } from './services/auth.service';
import { ApiService } from './services/api.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { Media } from '@awesome-cordova-plugins/media/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Camera } from '@awesome-cordova-plugins/Camera/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

/*import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';*/

//import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic/ngx";

import { CustomTranslateLoader } from './services/custom-translate-loader.service';

import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

//import { WebView } from '@ionic-native/ionic-webview/ngx';

import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';

import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';

import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';

//import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

/*export function createTranslateLoader(http: HttpClient) {
  //return new TranslateHttpLoader(http, 'assets/i18n/', '.json');

  return new TranslateHttpLoader(http, `${API_ENDPOINT}/static/i18n/`, '.json');

}*/

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot({animated: true}), AppRoutingModule, //FontAwesomeModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        /*useFactory: createTranslateLoader,
        deps: [HttpClient]*/
        useClass: CustomTranslateLoader
      }
    }),
    HttpClientModule],
  providers: [
    StatusBar,
    SplashScreen,
    Media,
    File,
    HTTP,
    InAppBrowser,
    FileTransfer,
    Camera,
    Facebook,
    //FileOpener,
    //FCM,
    OpenNativeSettings,
    AndroidPermissions,
    NativeAudio,
    AppVersion,
    //WebView,
    PhotoViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApiService,
    AuthService,
    SocialSharing,],
  bootstrap: [AppComponent],
})
export class AppModule {

  /*constructor(library: FaIconLibrary) { 
		library.addIconPacks(fas, fab, far);
	}*/

}
